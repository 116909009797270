import React from "react";
import Loader from "react-loader-spinner";
import "./LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className="loading">
      <div className="loading-container">
        <Loader type="Bars" color="#00BFFF" height={80} width={80} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
