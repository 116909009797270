import { Route } from "react-router";
import { appInitialState, appReducer } from "./store/AppReducer";
import AppContext from "./store/AppContext";
import React, { Suspense, useReducer } from "react";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";

const TopScreen = React.lazy(() => import("./screens/TopScreen/TopScreen"));
const MapScreen = React.lazy(() => import("./screens/MapScreen/MapScreen"));

const App = () => {
  const [state, dispatch] = useReducer(appReducer, appInitialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Suspense fallback={<LoadingSpinner />}>
        <Route exact path="/">
          <TopScreen />
        </Route>
        <Route exact path="/map/">
          <MapScreen />
        </Route>
      </Suspense>
    </AppContext.Provider>
  );
};

export default App;
