export const appInitialState = {
  mapId: 0,
  factionId: 0,
};

export const appReducer = (state, action) => {
  switch (action.type) {
    case "PATCH":
      return { ...state, ...action.value };

    default:
      return appInitialState;
  }
};
